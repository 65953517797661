export interface OauthTokenRequest {
  grant_type: 'password' | 'refresh_token';
  client_id: number;
  client_secret: string;
  username?: 'email';
  password?: 'password';
  refresh_token?: string;
}
export interface RegisterRequest {
  email: string;
  password: string;
  password_confirmation: string;
}
export interface CurrentUser {
  id: number;
  name: string;
  email: string;
}

export interface RemindPasswordRequest {
  email: string;
}

export interface UpdatePassswordRequest {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
  password_confirmation: string;
}
