<ng-container *ngIf="tyreLogsWithChanges">
  <ng-container *ngIf="tyreLogsWithChanges.length > 0; else emptyStateBlock">
    <div class="grid-table">
      <div class="header header--position"></div>
      <div class="header header--id">{{ 'id' | translate | uppercase }}</div>
      <div class="header header--name">{{ 'name' | translate }}/{{ 'season' | translate }}</div>
      <div class="header header--dot">{{ 'dot' | translate }}</div>
      <div class="header header--rim">{{ 'rim' | translate }}</div>
      <div class="header header--thread">{{ 'thread' | translate }}</div>
      <div class="header header--state-before">
        {{ 'state' | translate }}<br />
        {{ 'before-lc' | translate }}/{{ 'after-lc' | translate }}
      </div>
    </div>

    <div class="grid-table" *ngFor="let tyreLogWithChange of tyreLogsWithChanges; let i = index">
      <div class="column column--position">
        <app-tire-position-designation
          [position]="tyreLogWithChange.tyreLog.tyre_position"
        ></app-tire-position-designation>
      </div>
      <div class="column column--id">
        {{ tyreLogWithChange.tyreLog.tyre?.id || '-' }}
      </div>
      <div class="column column--name">
        <ng-container [ngSwitch]="tyreLogWithChange.isRim">
          <ng-container *ngSwitchCase="true">
            <div class="name-wrapper">
              <wchfs-icon
                svgIcon="pso:felga_m"
                style="width: 30px; height: 30px; position: relative; top: -4px; margin-right: -5px; left: -3px"
              ></wchfs-icon>
              <span>{{ tyreLogWithChange.getName() }}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="name-wrapper">
              <app-tyre-season [tyre]="tyreLogWithChange.tyreLog.tyre"></app-tyre-season>
              <app-tyre-info [tyre]="tyreLogWithChange.tyreLog?.tyre"></app-tyre-info>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="column column--dot">
        {{ tyreLogWithChange.tyreLog?.tyre?.dot || '-' }}
      </div>
      <div class="column column--rim">
        <app-rim-type [type]="tyreLogWithChange.tyreLog?.rim_type"></app-rim-type>
      </div>
      <div class="column column--thread">
        <app-tread-depth [depth]="tyreLogWithChange.tyreLog?.tread_depth"></app-tread-depth>
      </div>
      <div class="column column--state-before-after">
        <wchfs-icon
          *ngIf="tyreLogWithChange.tyreLog?.tyre_state_from; else emptyIcon"
          size="24"
          [svgIcon]="'pso:' + TyreState.getIcon(tyreLogWithChange.tyreLog?.tyre_state_from)"
          [wchfsTooltip]="TyreState.getTranslateKey(tyreLogWithChange.tyreLog?.tyre_state_from) | translate"
        ></wchfs-icon>

        <wchfs-icon
          *ngIf="tyreLogWithChange.tyreLog?.tyre_state?.value; else emptyIcon"
          size="24"
          [svgIcon]="'pso:' + TyreState.getIcon(tyreLogWithChange.tyreLog?.tyre_state?.value)"
          [wchfsTooltip]="TyreState.getTranslateKey(tyreLogWithChange.tyreLog?.tyre_state?.value) | translate"
        ></wchfs-icon>

        <ng-template #emptyIcon>
          <div class="empty-icon">-</div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyStateBlock>
    <app-empty-state [style.height]="'200px'"> {{ 'no-deposit-operations' | translate }}</app-empty-state>
  </ng-template>
</ng-container>
