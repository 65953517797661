import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompletionType, TyreLogResource, TyreLogWithChange, TyreParam, TyreParamType } from '@data/tyres/tyres.model';
import { DispositionOrigin, DispositionOriginValue, DispositionResource } from '@data/dispositions/dispositions.model';
import { TyreState, TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { Store } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';

@Component({
  selector: 'app-deposits-operations-table',
  templateUrl: './deposits-operations-table.component.html',
  styleUrls: ['./deposits-operations-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositsOperationsTableComponent {
  @Input() set disposition(value: DispositionResource) {
    if (this.store.selectSnapshot(ProfileState.routeRolePrefix) !== 'psoa') {
      this.excludeStates = [TyreState.DELETED];
    }
    this.setTyreLogsWithChanges(value.tyre_logs, value.origin.value);
  }

  excludeStates: TyreStateValue[] = [];
  TyreParamType = TyreParamType;
  TyreParam = TyreParam;
  tyreLogsWithChanges: TyreLogWithChange[];
  protected readonly TyreState = TyreState;
  protected readonly CompletionType = CompletionType;

  constructor(private store: Store) {}

  private setTyreLogsWithChanges(tyreLogs: TyreLogResource[], origin: DispositionOriginValue): void {
    if (origin === DispositionOrigin.DEPOSIT_EXTENSION) {
      this.tyreLogsWithChanges = tyreLogs.map(
        (tl) => new TyreLogWithChange(tl, tl.completion_type === CompletionType.RIM_ONLY)
      );
      return;
    }

    this.tyreLogsWithChanges = tyreLogs
      ?.filter((tl) => !this.excludeStates.includes(tl.tyre_state?.value))
      .filter((tyreLog: TyreLogResource) => tyreLog.tyre_state_from !== tyreLog.tyre_state?.value)
      .map((tl) => new TyreLogWithChange(tl, tl.completion_type === CompletionType.RIM_ONLY));
  }
}
