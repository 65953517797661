<div class="search-form-action flex-col space-between items-center fx-flex-100">
  <div class="flex-row space-between items-center fx-flex-100" style="width: 100%">
    <div (click)="isOpen = !isOpen" class="hide-content">
      <p>{{ isOpen ? labels.hide : labels.open }}</p>
      <wchfs-icon
        [ngClass]="{ 'hide-content-arrow-icon-rotated': isOpen }"
        class="hide-content-arrow-icon"
        size="7"
        svgIcon="wchfs:form_arrow_down"
      ></wchfs-icon>
    </div>
    <div *ngIf="isOpen" class="flex-row space-between items-center fx-flex-100">
      <div class="flex-row gap-16 items-center justify-center" style="width: 100%">
        <ng-content></ng-content>
      </div>
      <button
        (click)="submitOnClick()"
        *ngIf="showButton"
        class="search-form-submit-button"
        color="supporting-5-light-blue"
        size="s"
        pso-button
      >
        {{ labels.search }}
      </button>
    </div>
  </div>
</div>
