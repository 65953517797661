<div class="delete-modal flex-col align-center items-center">
  <div class="flex-row space-between items-center">
    <div class="flex-column jc-space-around items-center">
      <h2 class="text-supporting-3 text-h2">{{ data.title }}</h2>
    </div>
    <div class="btn" (click)="closeDialog()">
      <wchfs-icon color="secondary" svgIcon="wchfs:cross"></wchfs-icon>
    </div>
    <!-- <wchfs-icon color="secondary" svgIcon="wchfs:cross"></wchfs-icon> -->
  </div>
  <hr class="hr text-grey-5" />
  <div class="input-box flex-col align-center items-center"></div>
  <div class="flex-row flex-end items-end">
    <button wchfs-button (click)="closeDialog()" class="text-secondary">ANULUJ</button>
    <button pso-button (click)="confirmDelete()" color="supporting-5-light-blue">POTWIERDŹ</button>
  </div>
</div>
