import { AuthorizationResource, DispositionItemResource } from '@data/authorization/authorization.model';
import { RimTypeValue, SpareWheelType } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { MediaResource } from '@data/media/media.model';
import { NoteResource } from '@data/note/note.model';
import { TyreLogResource } from '@data/tyres/tyres.model';
import { VehicleResource } from '@data/vehicles/vehicles.model';
import { EnumResource } from '@shared/interfaces/enum-resource';
import { PaginationParams, SortingParams, TabParams } from '../filtering/filtering.interface';
import { FleetResource } from '@data/fleets/fleets.model';
import { WholesalerResource } from '@data/wholesalers/wholesalers.model';
import { DispositionAuthResource } from '@data/disposition-auth/disposition-auth.model';
import { StatusResource } from '@data/status/status.interfaces';
import { FormControl, FormGroup } from '@angular/forms';
import { InputOptionResource } from '@app/@feed-inputs';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';
import { WorkshopResource } from '@data/workshop/workshop.model';
import { UserResource } from '../users/users.model';
import { AttachmentResource } from '@data/_legacy/attachment/attachment.interfaces';
import { DateTimeString } from '@shared/interfaces/date-string';
import { SuborderType } from '@data/orders/orders.model';
import { Id } from '@core/http/crud-model';
import { ActivitiesResource, LogUserResource } from '@data/activities/activities.model';
import { CostGroupResource } from '@data/cost-group/cost-group.model';
import { RolesGroupEnum } from '@core/Roles';

export interface DispositionResource {
  id: number;
  created_at: DateTimeString;
  received_at: DateTimeString;
  request_id: string;
  fleet: FleetResource;
  workshop: WorkshopResource;
  supervisor: UserResource;
  vehicle: VehicleResource;
  amount: {
    value: number;
    previous_value: number | null;
    currency: string;
  };
  authorization: AuthorizationResource;
  cost_group: CostGroupResource;
  status: DispositionStatusName;
  notes: NoteResource[];
  attachment: AttachmentResource;
  services: any[] | null;
  order_number: string | null;
  number: string;
  // invoice: InvoiceResource;
  // quantity: number;
  disposition_items: DispositionItemResource[];
  auths: DispositionAuthResource[];
  mileage: number;
  rim_type: RimTypeValue;
  spare_wheel: SpareWheelType;
  tpms: boolean;
  tyre_logs: TyreLogResource[];
  draft_tyre_logs: TyreLogResource[];
  current_tyre_logs: TyreLogResource[];
  service_comment: string;
  system_comment: string;
  total_prices: {
    services: {
      fleet_price: string;
      workshop_price: string;
      quantity: number;
    };
  };
  media: MediaResource[];
  user: UserResource;
  pdf: MediaResource[];
  driver_name: string;
  driver_phone: string;
  updated_at: DateTimeString;
  verification_status: string;
  origin: EnumResource<DispositionOriginValue>;
  origin_disposition: DispositionResource;
  deferred_dispositions: DispositionResource[];
  verification_reason: string;
  verification_reason_description: string;
  wholesaler: WholesalerResource;
  is_cancelable: boolean;
  invoiced_status: DispositionInvoicedStatusValue;
  verified_at: DateTimeString;
  sms: string;
  updated_by: LogUserResource;
  latest_mileage_update: ActivitiesResource;
}

export interface DispositionColumns {
  full: string[];
  limited: string[];
}

export interface DispositionList {
  tabs: ListTabWithColumns<{ full: string[]; limited: string[] }>[];
}

export interface DispositionFilterParams extends PaginationParams, SortingParams, TabParams {
  dispositions: string;
  vehicles: InputOptionResource;
  id: InputOptionResource;
  workshops: string;
  fleets: string;
  price_from: string;
  price_to: string;
  date_from: string;
  date_to: string;
  cost_groups: string;
  services: string;
  vehicle_brands: string;
  authorization_status: string;
  status: string;
  self_fleets: string;
  is_authorized: boolean;
  invoices: string;
}

export type DispositionFilterParamsFormGroup = FormGroup<{
  vehicles: FormControl<InputOptionResource>;
  id: FormControl<InputOptionResource>;
  workshops: FormControl<string[]>;
  fleets: FormControl<string[]>;
  price_from: FormControl<string>;
  price_to: FormControl<string>;
  date_from: FormControl<string>;
  date_to: FormControl<string>;
  cost_groups: FormControl<string[]>;
  services: FormControl<string[]>;
  vehicle_brands: FormControl<string[]>;
  authorization_status: FormControl<string>;
  status: FormControl<string>;
  self_fleets: FormControl<string>;
}>;

export interface DispositionPdfStoreRequest {
  file: File;
  description?: string;
  roles_groups_names?: RolesGroupEnum[];
}

export type DispositionStatusName = 'draft' | 'pre_filled' | 'to_approval' | 'approved' | 'closed' | 'canceled';

export class DispositionStatus {
  static DRAFT: DispositionStatusName = 'draft';
  static PRE_FILLED: DispositionStatusName = 'pre_filled';
  static TO_APPROVAL: DispositionStatusName = 'to_approval';
  static APPROVED: DispositionStatusName = 'approved';
  static CLOSED: DispositionStatusName = 'closed';
  static CANCELED: DispositionStatusName = 'canceled';

  static isDraft(status: DispositionStatusName): boolean {
    return status === DispositionStatus.DRAFT;
  }

  static isPreFilled(status: DispositionStatusName): boolean {
    return status === DispositionStatus.PRE_FILLED;
  }

  static isNotApproved(status: DispositionStatusName): boolean {
    return ![DispositionStatus.APPROVED].includes(status);
  }

  static isEditable(status: DispositionStatusName): boolean {
    return DispositionStatus.isDraft(status) || DispositionStatus.isPreFilled(status);
  }

  static isCancelable(status: DispositionStatusName): boolean {
    return DispositionStatus.isDraft(status) || DispositionStatus.isPreFilled(status);
  }

  static isCloseable(status: DispositionStatusName): boolean {
    return ![DispositionStatus.APPROVED, DispositionStatus.CLOSED].includes(status);
  }

  static isPrintable(status: DispositionStatusName): boolean {
    return [DispositionStatus.CLOSED, DispositionStatus.APPROVED].includes(status);
  }

  static isMileageEditable(status: DispositionStatusName): boolean {
    return [DispositionStatus.CLOSED].includes(status);
  }

  static areDispositionItemsAddable(status: DispositionStatusName): boolean {
    return ![DispositionStatus.DRAFT, DispositionStatus.CANCELED].includes(status);
  }

  static showAdditionalStatus(status: DispositionStatusName): boolean {
    return [DispositionStatus.PRE_FILLED, DispositionStatus.DRAFT].includes(status);
  }

  static toStatusResource(status: DispositionStatusName): StatusResource {
    return new Map<DispositionStatusName, StatusResource>([
      [
        DispositionStatus.DRAFT,
        {
          value: status,
          label: DispositionStatus.getTranslateKey(status),
          hex_color: 'var(--supporting-5-light-blue)',
          icon: 'pso:disposition_status_draft',
        },
      ],
      [
        DispositionStatus.PRE_FILLED,
        {
          value: status,
          label: DispositionStatus.getTranslateKey(status),
          hex_color: 'var(--supporting-7-navy)',
          icon: 'pso:disposition_status_pre-filled',
        },
      ],
      [
        DispositionStatus.TO_APPROVAL,
        {
          value: status,
          label: DispositionStatus.getTranslateKey(status),
          hex_color: 'var(--ui-g2-gray2)',
          icon: 'pso:to_approval_s',
        },
      ],
      [
        DispositionStatus.APPROVED,
        {
          value: status,
          label: DispositionStatus.getTranslateKey(status),
          hex_color: 'var(--supporting-2-dark-green)',
          icon: 'pso:approved_s',
        },
      ],
      [
        DispositionStatus.CLOSED,
        {
          value: status,
          label: DispositionStatus.getTranslateKey(status),
          hex_color: 'var(--supporting-7-lighten20)',
          icon: 'pso:disposition_status_closed',
        },
      ],
      [
        DispositionStatus.CANCELED,
        {
          value: status,
          label: DispositionStatus.getTranslateKey(status),
          hex_color: 'var(--supporting-3-darken-red)',
          icon: 'pso:disposition_status_canceled',
        },
      ],
    ]).get(status);
  }

  static getTranslateKey(status: DispositionStatusName): string {
    return 'disposition-status.' + status;
  }
}

export type DispositionsVerificationStatusName = 'to_verification' | 'verified' | 'declined';

export class DispositionsVerificationStatus {
  static STATUS_TO_VERIFICATION: DispositionsVerificationStatusName = 'to_verification';
  static STATUS_VERIFIED: DispositionsVerificationStatusName = 'verified';
  static STATUS_DECLINED: DispositionsVerificationStatusName = 'declined';

  static isToVerification(status: DispositionsVerificationStatusName): boolean {
    return [DispositionsVerificationStatus.STATUS_TO_VERIFICATION].includes(status);
  }
}

export type DispositionOriginValue =
  | 'ui'
  | 'ui_mechanical'
  | 'driver_release'
  | 'release_action'
  | 'disposal'
  | 'paid_deposit'
  | 'deposit_extension'
  | 'seasonal_prefilled'
  | 'seasonal_prefilled_disabled_tracking'
  | 'deferred';

export class DispositionOrigin {
  static UI: DispositionOriginValue = 'ui';
  static UI_MECHANICAL: DispositionOriginValue = 'ui_mechanical';
  static DRIVER_RELEASE: DispositionOriginValue = 'driver_release';
  static RELEASE_ACTION: DispositionOriginValue = 'release_action';
  static DISPOSAL: DispositionOriginValue = 'disposal';
  static PAID_DEPOSIT: DispositionOriginValue = 'paid_deposit';
  static DEPOSIT_EXTENSION: DispositionOriginValue = 'deposit_extension';
  static SEASONAL_PREFILLED: DispositionOriginValue = 'seasonal_prefilled';
  static SEASONAL_PREFILLED_DISABLED_TRACKING: DispositionOriginValue = 'seasonal_prefilled_disabled_tracking';
  static RESERVATION_SALE = 'deposit_sale';
  static DEFERRED: DispositionOriginValue = 'deferred';
  static RELOCATION = 'relocation';
  static MIGRATION = 'migration';
  static ORDER_IMPORT = 'order_import';

  static editable(): DispositionOriginValue[] {
    return [
      DispositionOrigin.UI,
      DispositionOrigin.UI_MECHANICAL,
      DispositionOrigin.RELEASE_ACTION,
      DispositionOrigin.DISPOSAL,
      DispositionOrigin.PAID_DEPOSIT,
      DispositionOrigin.DEPOSIT_EXTENSION,
      DispositionOrigin.SEASONAL_PREFILLED,
      DispositionOrigin.SEASONAL_PREFILLED_DISABLED_TRACKING,
    ];
  }

  static preFilled(): DispositionOriginValue[] {
    return [DispositionOrigin.SEASONAL_PREFILLED, DispositionOrigin.SEASONAL_PREFILLED_DISABLED_TRACKING];
  }

  static isEditable(origin: DispositionOriginValue): boolean {
    return DispositionOrigin.editable().includes(origin);
  }

  static isDisposal(origin: DispositionOriginValue): boolean {
    return origin === DispositionOrigin.DISPOSAL;
  }

  static isPreFilled(value: DispositionOriginValue | undefined): boolean {
    return DispositionOrigin.preFilled().includes(value);
  }

  static isMechanical(value: DispositionOriginValue | undefined): boolean {
    return value === DispositionOrigin.UI_MECHANICAL;
  }
}

export type DispositionInvoicedStatusValue = 'invoiced' | 'invoiced_partially';

export class DispositionInvoicedStatus {
  static INVOICED: DispositionInvoicedStatusValue = 'invoiced';
  static INVOICED_PARTIALLY: DispositionInvoicedStatusValue = 'invoiced_partially';

  static toStatusResource(status: DispositionInvoicedStatusValue): StatusResource {
    return new Map<DispositionInvoicedStatusValue, StatusResource>([
      [
        DispositionInvoicedStatus.INVOICED,
        {
          value: status,
          label: DispositionInvoicedStatus.getTranslateKey(status),
          hex_color: 'var(--ui-g2-gray2)',
        },
      ],
      [
        DispositionInvoicedStatus.INVOICED_PARTIALLY,
        {
          value: status,
          label: DispositionInvoicedStatus.getTranslateKey(status),
          hex_color: 'var(--ui-g2-gray2)',
        },
      ],
    ]).get(status);
  }

  static getTranslateKey(status: DispositionInvoicedStatusValue): string {
    return 'disposition-invoice-status.' + status;
  }
}

export const dispositionHasSellServiceFromOrderWithReservation = (disposition: DispositionResource): boolean => {
  return !!disposition.disposition_items.find((di) => di.suborder?.type === SuborderType.WITH_RESERVATION);
};

export const dispositionIsInvoicedOrPartiallyInvoiced = (disposition: DispositionResource): boolean => {
  return [DispositionInvoicedStatus.INVOICED, DispositionInvoicedStatus.INVOICED_PARTIALLY].includes(
    disposition.invoiced_status
  );
};

export type DispositionsFilterKey = 'requests' | 'requests_invoice_mode' | 'requests_status_mode';

export const getInvoiceIdsFromDisposition = (disposition: DispositionResource): Id[] => {
  return [...new Set(disposition.disposition_items.filter((di) => di.invoice_id).map((di) => di.invoice_id))];
};

export const getZamDateFromDisposition = (disposition: DispositionResource): DateTimeString => {
  return disposition.disposition_items.find((di) => di.suborder)?.suborder?.confirmed_at || null;
};

export class DispositionLastEdit {
  private readonly _role: string;
  private readonly _username: string;
  private readonly _date: string;
  private readonly _canShowLastEdition: boolean;

  constructor(private disposition: DispositionResource) {
    this._canShowLastEdition = !!this.disposition?.user || !!this.disposition?.updated_by;
    this._username = this.disposition?.updated_by ? this.disposition?.updated_by?.name : this.disposition?.user?.name;
    this._role = this.disposition?.updated_by
      ? DispositionLastEdit.getFormattedRoles(this.disposition?.updated_by)
      : DispositionLastEdit.getFormattedRoles(this.disposition?.user);
    this._date = this.disposition?.updated_at;
  }

  getRole(): string {
    return this._role;
  }

  getUsername(): string {
    return this._username;
  }

  getDate(): string {
    return this._date;
  }

  canShowLastEdition(): boolean {
    return this._canShowLastEdition;
  }

  static getFormattedRoles(user: LogUserResource | UserResource): string {
    return user?.roles.map((r) => r.roles_group?.name).join(', ');
  }
}
