import { CompletionTypeSlug, TyrePositionSlug } from '@data/tyres/tyres.model';
import { Id } from '@app/@core/http/crud-model';
import { AddTyreContextName } from '@app/@data/disposition-add-edit/add-tyre/add-tyre.model';
import { ProductTyreResource } from '@data/product-tyres/product-tyres.model';
import { SelectData } from '@app/@pso-ui/select/select.interfaces';
import { RimTypeValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { FormControl, FormGroup } from '@angular/forms';

export interface NewTireRow {
  dot: string;
  position: TyrePositionSlug;
  tire: ProductTyreResource;
  tread: string;
  rim: RimTypeValue;
  complete_wheel: 'yes_alu' | 'yes_steel' | 'no' | 'yes';
}

export interface NewTiresModalClose {
  vehicle_id: string;
  tyres: NewTiresResource[];
}

export interface NewTiresResource {
  dot: string;
  quantity: string;
  tableRowInner: NewTireRow[];
}

export interface DepositWaitingPatchRequest {
  vehicle_id: Id;
  tyres: DepositWaitingTyres[];
}

export interface DepositWaitingTyres {
  tread_depth: number;
  dot: string;
  product_tyre_id: Id;
  completion_type: CompletionTypeSlug;
  rim_type: RimTypeValue;
}

export interface AddNewTireModalData {
  availablePositions: TyrePositionSlug[];
  addTyreContext: AddTyreContextName;
  tyresInDepositLength: number;
  rimType: RimTypeValue;
  completeWheel: CompleteWheelCarValue;
}

export type CompleteWheelValue = 'yes_alu' | 'yes_steel' | 'no';

export class CompleteWheel {
  static YES_ALU: CompleteWheelValue = 'yes_alu';
  static YES_STEEL: CompleteWheelValue = 'yes_steel';
  static NO: CompleteWheelValue = 'no';

  static yes(): CompleteWheelValue[] {
    return [CompleteWheel.YES_ALU, CompleteWheel.YES_STEEL];
  }

  static selectData(): SelectData<CompleteWheelValue>[] {
    return [
      { value: CompleteWheel.YES_ALU, label: 'TAK - Alu' },
      { value: CompleteWheel.YES_STEEL, label: 'TAK - Stal' },
      { value: CompleteWheel.NO, label: 'Nie' },
    ];
  }
}

export type CompleteWheelCarValue = 'yes' | 'no';

export class CompleteWheelCar {
  static YES: CompleteWheelCarValue = 'yes';
  static NO: CompleteWheelCarValue = 'no';

  static yes(): CompleteWheelCarValue[] {
    return [CompleteWheelCar.YES];
  }

  static selectData(): SelectData<CompleteWheelCarValue>[] {
    return [
      { value: CompleteWheelCar.YES, label: 'Tak' },
      { value: CompleteWheelCar.NO, label: 'Nie' },
    ];
  }
}

export type AddNewTireGroupFormGroup = FormGroup<{
  quantity: FormControl<string>;
  dot: FormControl<string>;
}>;

export type AddNewTireItemFormGroup = FormGroup<{
  tread: FormControl<number | null>;
  dot: FormControl<string | null>;
  position: FormControl<TyrePositionSlug | null>;
  complete_wheel: FormControl<CompleteWheelValue | CompleteWheelCarValue | null>;
  rim: FormControl<RimTypeValue>;
}>;

export class AddNewTireGroup {
  constructor(public id: Id, public form: AddNewTireGroupFormGroup, public items: AddNewTireItem[]) {
    this.form.controls.dot.valueChanges.subscribe((value) => {
      this.items.map((i) => i.form.controls.dot).forEach((dotControl) => dotControl.setValue(value));
    });
  }

  isValid(): boolean {
    return !this.items.find((i) => !i.form.valid);
  }
}

export class AddNewTireItem {
  constructor(public product: ProductTyreResource, public form: AddNewTireItemFormGroup) {}
}
