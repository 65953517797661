<h2>{{ 'notes' | translate }}</h2>
<div class="notes" #chatContainer [scrollTop]="chatContainer.scrollHeight">
  <div class="message-outer" *ngFor="let note of notes">
    <div class="message-inner" [class]="note.role_slug">
      <div class="date-role">
        <div class="date">{{ note.created_at | date: 'dd.MM.yyyy HH:mm' }}</div>
        <div class="role">
          {{ note?.author?.name ?? 'none' | translate }}
          (
          <ng-container *ngFor="let role of note?.author?.roles">{{ role?.name ?? '-' | translate }} </ng-container>
          )
        </div>
      </div>
      <div class="message">
        {{ note.text }}
      </div>
    </div>
  </div>
</div>

<div class="add-note" *ngIf="!readonly">
  <app-textarea-with-limitation
    [placeholder]="'Wpisz treść notatki...'"
    [control]="control"
  ></app-textarea-with-limitation>
  <div class="button-wrapper">
    <button
      [dataCy]="'add-note-button'"
      pso-button
      [color]="'primary-b-blue'"
      [disabled]="!control.value"
      (click)="addNote(); this.control.reset()"
      *ngIf="(loading$ | async) === false; else loader"
    >
      {{ 'save' | translate | uppercase }}
    </button>
  </div>
</div>

<ng-template #loader>
  <app-dot-loader size="s"></app-dot-loader>
</ng-template>
